async function fetchAppVersion() {
  const manifest = await fetch("/manifest.json").then((res) => res.json())
    .catch(() => {});

  return manifest["app.js"] ?? "";
}

function runAtSpecificHour(hour, callback) {
  const calculateInitialTimeout = () => {
    const now = new Date();
    const targetTime = new Date(now);
    targetTime.setHours(hour, 0, 0, 0);

    // If target time is in the past, set it for the next day
    if (targetTime <= now) {
      targetTime.setDate(targetTime.getDate() + 1);
    }

    return targetTime - now;
  };

  const initialTimeout = calculateInitialTimeout();
  console.info(`[UPDATER] The updater will run in ${initialTimeout}ms`);

  // Set the initial timeout
  setTimeout(function runCallback() {
    callback();

    // After the initial run, set an interval for 24 hours
    console.info("[UPDATER] Enqueue next check update");
    setInterval(callback, 24 * 60 * 60 * 1000);
  }, initialTimeout);
}

export async function watchForUpdates(app, { reloadHour }) {
  if (!reloadHour) {
    return;
  }

  runAtSpecificHour(reloadHour, async () => {
    window.location.reload();
  });
}
