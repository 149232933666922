import { watchForUpdates } from "@/lib/updater";
import axios from 'axios'

export default {
    async mounted() {
        await this.getTerminal();
        await this.getSettings();

        if (this.$store.getters['transaction/transaction'].id) {
            this.$router.replace({ name: 'transaction' })
        }
        else if (this.$store.getters['terminal/terminal'].startpage == 'clerk.login' && !this.$store.getters['transaction/transaction'].clerk_id) {
            this.$router.replace({ name: 'clerk.login' })
        }
        else if (this.$store.getters['terminal/terminal'].startpage == 'tables' && this.$store.getters['transaction/transaction'].prestine) {
            this.$router.replace({ name: 'pos.tables' });
        }

        watchForUpdates(this, {
            reloadHour: this.$store.getters["terminal/terminal"].reload_hour,
        }).catch((error) => console.error(error));
    },
    methods: {
        async getTerminal() {


            let dataTerminal = localStorage.getItem('masterdata.terminal');

            if (dataTerminal === null) {
                try {
                    const response = await axios({
                        url: '/terminal',
                        method: 'get',
                    });
                    this.$i18n.locale = response.data.data.attributes.language;
                // axios.get('/terminal').then(response => {
                    this.$store.dispatch('terminal/terminal', response.data.data)
                    this.$store.dispatch('transaction/storeDefaultItems', response.data.data.relationships.default_items)
                    this.$store.dispatch('location/location', response.data.data.relationships.location)
                    this.$store.dispatch('printer/printer', response.data.data.relationships.printer)

                    localStorage.setItem('masterdata.terminal', true);
                } catch (error) {

                    localStorage.setItem('masterdata.terminal', false);

                    if(error.response === undefined){
                        return;
                    } else{
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Fout opgetreden",
                            text: "Oeps er ging iets fout..",
                        }, 2000);
                    }
                }
                // await axios.get('/terminal')
                //     .then(response => {
                //         this.$i18n.locale = response.data.data.attributes.language;
                //     // axios.get('/terminal').then(response => {
                //         this.$store.dispatch('terminal/terminal', response.data.data)
                //         this.$store.dispatch('transaction/storeDefaultItems', response.data.data.relationships.default_items)
                //         this.$store.dispatch('location/location', response.data.data.relationships.location)
                //         this.$store.dispatch('printer/printer', response.data.data.relationships.printer)
                //     }).catch((error) => {
                //         if(error.response === undefined){
                //             return;
                //         }else{
                //             this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                //         }

                //     })
            }
        },
        async getSettings() {

            let dataSettings = localStorage.getItem('masterdata.settings');

            if (!dataSettings) {
                try {
                    const response = await axios({
                        url: '/settings',
                        method: 'get',
                    });
                    this.$store.dispatch('settings/settings', response.data.data)

                    localStorage.setItem('masterdata.settings', true);

                } catch (error) {
                    localStorage.setItem('masterdata.settings', false);

                    if(error.response === undefined){
                        return;
                    } else{
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Fout opgetreden",
                            text: "Oeps er ging iets fout..",

                        }, 2000);
                    }
                }
            }
        },
    },
	computed: {
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
    }
}
